import React from "react";
import Layout from "../components/layout";
import { Container, Row, Col } from "react-bootstrap";
import Seo from "../components/seo";
//Images
import { StaticImage } from "gatsby-plugin-image";

const LocalAuthorities = () => {
    return (
        <Layout>
            <Seo
                title="Lokale Behörden - BIKESAFE"
                description="Bike Safe hat mit vielen lokalen Behörden zusammengearbeitet, um in Stadtzentren, in der Nähe von Gebäuden der Öffentlichen Verwaltung, auf Parkplätzen, Bahnhöfen und Bushaltestellen sowie an Bildungseinrichtungen Möglichkeiten für das Parken und die Unterbringungen von Fahrrädern zu schaffen." />
            <Container>
                <Row>
                    <Col sm="12">
                        <h1 className="mt-5 mb-5 text-center">
                            Lokale Behörden
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center">
                        <p className="flow-text">
                            Bike Safe hat mit vielen lokalen Behörden zusammengearbeitet, um in Stadtzentren, in der Nähe von Gebäuden der Öffentlichen Verwaltung, auf Parkplätzen, Bahnhöfen und Bushaltestellen sowie an Bildungseinrichtungen Möglichkeiten für das Parken und die Unterbringungen von Fahrrädern zu schaffen.
                        </p>
                        <p className="flow-text">
                            Leicht zugängliche Fahrradparkplätze ermutigen zum Fahrradfahren, verringern so den Verkehr und schonen die Umwelt. Sie können Ihre Stadt zu einer "Fahrradstadt" machen und ihr Image so erheblich aufwerten! Fahrradfahren trägt zur Gesundheit der Menschen bei.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center">
                        <StaticImage placeholder="blurred" src="../images/localauthoritypic.jpg" className="shadow mt-5 mb-5 fluid rounded" />
                        <p>
                            <em className="flow-text">"Ihr Service war großartig – ich würde Ihr Unternehmen definitiv weiterempfehlen"
                                <strong> Gina Harkell, Radverkehrsbeauftragter, London Borough of Waltham Forest</strong>.</em></p>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

export default LocalAuthorities;